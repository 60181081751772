import clsx from "clsx";

export type InputProps = {
  label?: string;
  register: any;
  field: string;
  error: any;
  isEditable?: boolean;
  hint?: string | JSX.Element;
  accept?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

const InputFile = (props: InputProps) => {
  const { label, register, error, field, isEditable, hint, disabled, ...rest } =
    props;
  const inputClasses = clsx("border-none mt-1", {
    "bg-transparent": !disabled,
    "bg-gray-200 cursor-not-allowed": disabled,
    "bg-white": isEditable && !disabled,
  });

  return (
    <div className="w-full flex flex-col items-start">
      <label
        htmlFor={field}
        className="flex items-center gap-1.5 text-xs font-century-gothic-bold"
      >
        {label}
        {error && <span className="text-red-500">[{error.message}]</span>}
      </label>
      <input
        type="file"
        id={field}
        {...register}
        aria-invalid={error ? "true" : "false"}
        {...rest}
        autoComplete="none"
        defaultValue={rest.defaultValue}
        className={inputClasses}
        disabled={disabled}
      />
      {hint && <span className="mt-1 text-xs text-gray-500">{hint}</span>}
    </div>
  );
};

export default InputFile;
