import { useState } from "react";
import { photoChallengesSchema } from "assets/constants/photoChallenges";
import { Button } from "../../components/ui";
import { useOutletContext } from "react-router-dom";
import { TEvent, TPhotoChallenge } from "@services/types";
import { PHOTO_CHALLENGE_FEATURE_FLAG } from "../../constants";
import { updateEvent } from "../../services/events";
import FeatureToggle from "./FeatureToggle";

const PHOTO_CHALLENGES_LIMIT = 15;

export default function PhotoChallenge() {
  const { event } = useOutletContext() as { event: TEvent };

  const featureEnabled = event.betaFeatures?.includes(
    PHOTO_CHALLENGE_FEATURE_FLAG
  );
  const [isPhotoChallengeEnabled, setIsPhotoChallengeEnabled] = useState(
    featureEnabled as boolean
  );

  const currentActiveQuestions = event.photoChallenge?.map((q) => q.id) ?? [];
  const [activeQuestions, setActiveQuestions] = useState<string[]>(
    currentActiveQuestions
  );

  const onPhotoChallengeSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    if (isPhotoChallengeEnabled) {
      const photoChallenge: TPhotoChallenge[] = [];

      activeQuestions.forEach((questionId) => {
        const question = photoChallengesSchema.find((q) => q.id === questionId);

        if (!question) {
          console.error(`Question with id ${questionId} not found!`);
          return;
        }

        photoChallenge.push(question);
      });

      const betaFeatures = new Set(event.betaFeatures);
      betaFeatures.add(PHOTO_CHALLENGE_FEATURE_FLAG);
      event.betaFeatures = Array.from(betaFeatures);
      event.photoChallenge = photoChallenge;
    } else {
      event.betaFeatures = event.betaFeatures?.filter(
        (feature) => feature !== PHOTO_CHALLENGE_FEATURE_FLAG
      );
    }

    await updateEvent(event);
  };

  const handleQuestionToggle = (questionId: string) => {
    setActiveQuestions((prev) => {
      if (prev.includes(questionId)) {
        return prev.filter((id) => id !== questionId);
      }
      if (prev.length >= PHOTO_CHALLENGES_LIMIT) {
        alert("Você atingiu o limite máximo de 15 desafios!");
        return prev;
      }
      return [...prev, questionId];
    });
  };

  return (
    <div className="flex flex-col items-start justify-start gap-4 w-full">
      <h1 className="w-full flex items-center gap-3">
        Foto Desafio
        <FeatureToggle
          checked={isPhotoChallengeEnabled}
          onChange={setIsPhotoChallengeEnabled}
        />
      </h1>
      <div className="flex items-center gap-2">
        <h3 className="">{activeQuestions.length}/15 desafios selecionados</h3>
      </div>
      <form
        className="flex flex-col justify-start gap-4 w-full"
        onSubmit={onPhotoChallengeSubmit}
      >
        {photoChallengesSchema.map((challenge, index) => (
          <div key={challenge.id} className="flex items-center gap-2">
            <input
              type="checkbox"
              className="h-6 w-6"
              checked={activeQuestions.includes(challenge.id)}
              onChange={() => handleQuestionToggle(challenge.id)}
              disabled={!isPhotoChallengeEnabled}
            />
            <h3>{`${index + 1}. ${challenge.text}`}</h3>
          </div>
        ))}

        <div className="pt-5">
          <Button type="submit">Salvar</Button>
        </div>
      </form>
    </div>
  );
}
