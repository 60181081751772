import InputCheckbox from "./InputCheckbox";

type InputProps = {
  label: string;
  register: any;
  error?: string | undefined | null;
  field: string;
  defaultValue?: string;
  description?: string;
  hint?: string;
  options?: { label: string; value: string }[];
  disabled?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

const InputCheckboxGroup = ({
  label,
  register,
  error,
  field,
  description,
  hint,
  options,
  disabled,
}: InputProps) => {
  return (
    <div className="w-full py-2 flex flex-col gap-2">
      <label className="flex items-center gap-1.5 text-xs font-century-gothic-bold">
        {label}
        {error && <span className="text-red-500">{error}</span>}
      </label>
      {description && <span>{description}</span>}
      <div className="flex flex-col items-start gap-2">
        {options?.map((option) => (
          <InputCheckbox
            key={option.value}
            field={field}
            register={register}
            label={option.label}
            value={option.value}
            disabled={disabled}
          />
        ))}
      </div>
      {hint && <span className="text-xs text-gray-400">{hint}</span>}
    </div>
  );
};

export default InputCheckboxGroup;
