import dataMigration from "../../services/dataMigration";
import { useEffect } from "react";

const Migration = () => {
  useEffect(() => {
    dataMigration.migrate();
  }, []);
  return (
    <div>
      <h1>Migration</h1>
    </div>
  );
};

export default Migration;
