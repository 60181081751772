import FirebaseAuthService from "../../libs/firebase/FirebaseAuthService";
import Logo from "../../assets/svg/Logo";
import React, { useCallback } from "react";
import { NavLink, useNavigate } from "react-router-dom";

function NavigationLink({
  to,
  children,
}: {
  to: string;
  children: React.ReactNode;
}) {
  return (
    <NavLink
      className={({ isActive }) =>
        [
          isActive
            ? "font-bold underline"
            : "no-underline font-semibold hover:underline",
          " rounded-t-xl p-2 text-lg whitespace-nowrap ",
        ].join(" ")
      }
      to={to}
      end
    >
      {children}
    </NavLink>
  );
}

export default function Header() {
  const navigate = useNavigate();
  const onLogout = useCallback(() => {
    FirebaseAuthService.logoutUser();
    navigate("/admin/login", { replace: true });
  }, [navigate]);

  return (
    <div className="w-full bg-red-400 text-white">
      <div className="h-full flex items-center justify-between max-w-6xl mx-auto">
        <NavigationLink to="/admin">
          <Logo width={200} />
        </NavigationLink>
        <div className="flex items-center gap-2">
          <NavigationLink to="/admin">Home</NavigationLink>
          <NavigationLink to="/admin/events">Eventos</NavigationLink>
          <NavigationLink to="/admin/events/create">
            Criar Evento
          </NavigationLink>
          |
          <button
            type="button"
            onClick={onLogout}
            className="button border-none hover:bg-red-300"
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
}
