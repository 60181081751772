import { Link, useOutletContext } from "react-router-dom";
import { Button } from "../../components/ui";
import { useCallback, useMemo, useState } from "react";
import {
  EUploadMenuItem,
  TEvent,
  UPLOAD_MENU_ITEMS,
} from "../../services/types";
import { UPLOADS_IN_MENU_FEATURE_FLAG } from "../../constants";
import { updateEvent } from "../../services/events";
import { useFieldArray, useForm } from "react-hook-form";
import { InputFile, InputToggle } from "../../components/ui/form";
import * as DigitalOceanSpaces from "../../services/digital_ocean/spaces";
import FeatureToggle from "./FeatureToggle";

type TMenuUploadItem = {
  kind: string;
  enabled: boolean;
  storagePath: string;
  file?: any;
};

type TUploadsInMenuForm = {
  menuUploads: TMenuUploadItem[];
};

const UploadMenu = () => {
  const { event } = useOutletContext() as { event: TEvent };
  const featureEnabled = event.betaFeatures?.includes(
    UPLOADS_IN_MENU_FEATURE_FLAG
  );
  const [isUploadMenuEnabled, setIsUploadMenuEnabled] = useState(
    featureEnabled as boolean
  );
  const [uploading, setUploading] = useState(false);

  const menuUploadsDefault = useMemo(() => {
    const uploadKinds = Object.keys(EUploadMenuItem);

    return uploadKinds.map((kind) => {
      const menuUpload = event.menuUploads?.find(
        (menuUpload) => menuUpload.id === kind
      );

      if (menuUpload) {
        return {
          kind,
          enabled: menuUpload.enabled,
          storagePath: menuUpload.storagePath,
        } as TMenuUploadItem;
      }

      return {
        kind,
        enabled: false,
      } as TMenuUploadItem;
    });
  }, [event.menuUploads]);

  const {
    register,
    control,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm<TUploadsInMenuForm>({
    disabled: !isUploadMenuEnabled,
    defaultValues: {
      menuUploads: menuUploadsDefault,
    },
  });

  const { fields: menuUploadsFields } = useFieldArray({
    control,
    name: "menuUploads",
  });

  const handleShowFile = useCallback(
    async (e: React.MouseEvent<HTMLAnchorElement>, storageKey: string) => {
      e.preventDefault();

      const fileUrl = await DigitalOceanSpaces.getFileUrl(storageKey);
      const link = document.createElement("a");
      link.href = fileUrl;
      link.target = "_blank";
      link.rel = "noopener noreferrer";
      link.click();
    },
    []
  );

  const handleFileUpload = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      setUploading(true);

      const fileInput = e.target;

      if (fileInput.files && fileInput.files[0]) {
        const file = fileInput.files[0];
        const fileInputIndex = Number(
          fileInput.getAttribute("data-input-index")
        );
        const uploadKind = fileInput.getAttribute("data-kind");
        const fileExt = file.name.split(".").pop();
        const fileStoragePath = `menu/${event.id}/${uploadKind}.${fileExt}`;

        await DigitalOceanSpaces.uploadFile(file, fileStoragePath);

        setValue(`menuUploads.${fileInputIndex}.storagePath`, fileStoragePath);

        setUploading(false);
      }
    },
    [event.id, setValue, setUploading]
  );

  const onUploadMenuSubmit = async (data: TUploadsInMenuForm) => {
    if (isUploadMenuEnabled) {
      const betaFeatures = new Set(event.betaFeatures);
      betaFeatures.add(UPLOADS_IN_MENU_FEATURE_FLAG);
      event.betaFeatures = Array.from(betaFeatures);
    } else {
      event.betaFeatures = event.betaFeatures?.filter(
        (feature) => feature !== UPLOADS_IN_MENU_FEATURE_FLAG
      );
    }

    const menuUploadsEnabled = data.menuUploads.filter(
      (menuUpload) => menuUpload.enabled
    );
    const menuUploads = menuUploadsEnabled.map((menuUpload) => ({
      id: menuUpload.kind as EUploadMenuItem,
      enabled: menuUpload.enabled,
      storagePath: menuUpload.storagePath,
    }));

    event.menuUploads = menuUploads;

    await updateEvent(event);
  };

  return (
    <div className="flex flex-col items-start justify-start gap-4 w-full">
      <h1 className="w-full flex items-center gap-3">
        Adicionar arquivos no menu do evento
        <FeatureToggle
          checked={isUploadMenuEnabled}
          onChange={setIsUploadMenuEnabled}
        />
      </h1>

      <form
        className="flex flex-col justify-start gap-4 w-full mt-5"
        onSubmit={handleSubmit(onUploadMenuSubmit)}
        noValidate={true}
      >
        {menuUploadsFields.map((menuUpload: TMenuUploadItem, index) => (
          <fieldset
            className="flex flex-col gap-4 w-full border border-gray-200 p-5"
            disabled={!isUploadMenuEnabled}
            key={menuUpload.kind}
          >
            <legend className="text-lg font-bold flex gap-3">
              {
                UPLOAD_MENU_ITEMS[
                  menuUpload.kind as keyof typeof UPLOAD_MENU_ITEMS
                ]
              }

              <InputToggle
                label="Ativar"
                register={register(`menuUploads.${index}.enabled`)}
                field={`menuUploads.${index}.enabled`}
                error={errors.menuUploads?.[index]?.enabled}
                disabled={!isUploadMenuEnabled}
              />
            </legend>

            <div className="py-5">
              <InputFile
                register={register(`menuUploads.${index}.file`)}
                field={`menuUploads.${index}.file`}
                onChange={handleFileUpload}
                accept="application/pdf,image/*"
                data-input-index={index}
                data-kind={menuUpload.kind}
                error={errors.menuUploads?.[index]?.file}
                hint={
                  menuUpload.storagePath && (
                    <Link
                      to="#"
                      onClick={(e) => handleShowFile(e, menuUpload.storagePath)}
                      className="ml-3"
                    >
                      Visualizar arquivo
                    </Link>
                  )
                }
                isEditable={!isUploadMenuEnabled}
                disabled={!isUploadMenuEnabled}
              />
            </div>
          </fieldset>
        ))}

        <div className="pt-5">
          <Button type="submit" disabled={uploading}>
            Salvar
          </Button>
        </div>
      </form>
    </div>
  );
};

export default UploadMenu;
