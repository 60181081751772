import clsx from "clsx";
import { Control } from "react-hook-form";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import "react-phone-number-input/style.css";

export type InternationalPhoneInputProps = {
  label: string;
  field: string;
  defaultCountry?: string;
  defaultValue?: string;
  error: any;
  isEditable?: boolean;
  hint?: string;
  control: Control<any>;
  rules?: object;
} & React.InputHTMLAttributes<HTMLInputElement>;

const InternationalPhoneInput = ({
  label,
  field,
  defaultCountry = "BR",
  defaultValue,
  error,
  isEditable,
  hint,
  control,
  rules,
  disabled,
  ...rest
}: InternationalPhoneInputProps) => {
  const inputClasses = clsx({
    "bg-transparent": !disabled,
    "bg-gray-200 cursor-not-allowed": disabled,
    "bg-white": isEditable && !disabled,
  });

  return (
    <div className="w-full flex flex-col gap-1.5 items-start">
      <label
        htmlFor={field}
        className="flex items-center gap-1.5 text-xs font-century-gothic-bold"
      >
        {label}
        {error && <span className="text-red-500">[{error.message}]</span>}
      </label>

      <div className="w-full">
        <PhoneInputWithCountry
          international
          withCountryCallingCode
          name={field}
          defaultCountry={defaultCountry as any}
          control={control}
          rules={rules}
          error={error}
          defaultValue={defaultValue}
          className={inputClasses}
          disabled={disabled || !isEditable}
          {...rest}
        />
      </div>

      {hint && <span className="mt-1 text-xs text-gray-500">{hint}</span>}
    </div>
  );
};

export default InternationalPhoneInput;
