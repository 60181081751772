import { ActionFunctionArgs } from "react-router-dom";
import { getEventByCode } from "./events";
import { getUploadsByEventId } from "./uploads";

export const eventLoader = async ({ params }: ActionFunctionArgs) => {
  const { eventCode } = params;

  if (!eventCode) {
    throw new Error("Event code not found");
  }

  const event = await getEventByCode(eventCode);

  if (!event) {
    throw new Error("Event not found");
  }

  return { ...event };
};

export const eventWithUploadsLoader = async ({
  params,
}: ActionFunctionArgs) => {
  const { eventCode } = params;

  if (!eventCode) {
    throw new Error("Event code not found");
  }

  const event = await getEventByCode(eventCode);

  if (!event) {
    throw new Error("Event not found");
  }

  const uploads = await getUploadsByEventId(event.id as string);

  return { ...event, uploads };
};

export const eventWithUploadsForPublicGalleryLoader = async ({
  params,
}: ActionFunctionArgs) => {
  const { eventCode } = params;

  if (!eventCode) {
    throw new Error("Event code not found");
  }

  const event = await getEventByCode(eventCode);

  if (!event) {
    throw new Error("Event not found");
  }

  if (!event.publicGallery) {
    throw new Error("Public gallery not found");
  }

  const uploads = await getUploadsByEventId(event.id as string);

  return { ...event, uploads };
};
