import FirebaseFirestore from "@firebase/firestore-types";

export enum EEventKind {
  Recorder = "recorder",
  Uploader = "uploader",
}

export enum EUploadMenuItem {
  foodMenu = "food_menu",
  schedule = "schedule",
}

export const EVENT_FEATURES = {
  recorder: "Vídeo Recado",
  uploader: "Galeria da Festa",
};

export const UPLOAD_MENU_ITEMS = {
  foodMenu: "Cardápio",
  schedule: "Programação",
};

export type TEvent = {
  id?: string;
  kind?: EEventKind;
  features?: string[];
  title: string;
  code?: string;
  date: string;
  releaseDate: string;
  realtimeGallery?: boolean;
  publicGallery?: boolean;
  bannerUrl?: string;
  languages?: string[];
  city?: string;
  state?: string;
  country?: string;
  totalGuests?: string;
  contacts?: TContact[];
  searchIndex?: string[];
  customization?: TCustomization;
  compressed_video_collection_url?: string;
  createdAt?: string;
  updatedAt?: string;
  owners?: string[];
  betaFeatures?: string[];
  quiz?: TQuizQuestion[];
  bingo?: TBingoChallenge[];
  photoChallenge?: TPhotoChallenge[];
  teamMatch?: TTeamMatchQuestion[];
  menuUploads?: TUploadMenuItem[];
};

export type TVideo = {
  id?: string;
  eventId: string;
  eventCode: string;
  status: string;
  thumbnailFileName: string;
  thumbnailUrl: string;
  videoFileExtension: string;
  videoFileName: string;
  videoUrl: string;
  createdAt?: string;
  updatedAt?: string;
};

export type TUpload = {
  id?: string;
  eventId: string;
  eventCode: string;
  status: string;
  fileName: string;
  fileUrl: string;
  fileExt: string;
  fileSize: number;
  fileStoragePath: string;
  convertedFileStoragePath?: string;
  optimizedFileStoragePath?: string;
  thumbnailStoragePath?: string;
  createdAt?: string;
  updatedAt?: string;
};

export type TEventWithUploads = TEvent & { uploads: TUpload[] };

type TCustomization = {
  theme: TThemeProps;
  template: TTemplateProps;
};

type TThemeProps = {
  name: string;
  themeColor: string;
  backgroundColor: string;
  cardShape: string;
  buttonShape: string;
};

export type TTemplateProps = {
  name: string;
  titleFontSize?: string;
  titleFontFamily?: string;
  titleOneText?: string;
  titleTwoText?: string;
  subtitleText?: string;
};

export type TContact = {
  name: string;
  email?: string;
  whatsapp?: string;
  instagram?: string;
  reference?: string;
  notify?: string;
  role?: string;
  owner?: boolean;
};

export type TLog = {
  id?: string;
  eventId: string;
  message: string;
  createdAt?: string;
  updatedAt?: string;
};

export type TQuizQuestion = {
  id: string;
  text: string;
  options: string[];
  correct: string;
};

export type TTeamMatchQuestion = {
  id: string;
  text: string;
  teamA: string;
  teamB: string;
};

export type TPhotoChallenge = {
  id: string;
  text: string;
};

export type TBingoChallenge = {
  id: string;
  text: string;
};

export type TUploadMenuItem = {
  id: EUploadMenuItem;
  storagePath?: string;
  enabled: boolean;
};

export type TDocument = FirebaseFirestore.DocumentData;
