import React from "react";

type Props = {
  type?: "button" | "submit" | "reset";
  className?: string;
  color?: string;
  hoverColor?: string;
  activeColor?: string;
  disabledColor?: string;
  textColor?: string;
  successColor?: string;
  successText?: string;
  padding?: string;
  children: React.ReactNode;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export default function Button(props: Props) {
  const {
    type = "button",
    className,
    color = "bg-red-400",
    hoverColor = "bg-red-500",
    activeColor = "bg-red-700",
    disabledColor = "bg-red-300",
    textColor = "text-white",
    padding = "px-3",
    children,
    ...rest
  } = props;

  const classes = [
    "text-base min-h-[40px] px-5 font-bold h-10 rounded-md focus:outline-none focus:ring-1 focus:ring-gray-300",
    textColor,
    padding,
  ];

  if (rest.disabled) {
    classes.push(disabledColor);
  } else {
    classes.push(color);
    classes.push(`hover:${hoverColor}`);
    classes.push(`active:${activeColor}`);
  }

  return (
    <button
      className={`${classes.join(" ")} ${className}`}
      type={type}
      {...rest}
    >
      {children}
    </button>
  );
}
