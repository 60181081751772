const ServiceTermsText = () => {
  return (
    <div className="text-sm p-12">
      <h1 className="text-center text-lg uppercase pb-6">
        Termos de Uso - Vídeo recados
      </h1>

      <p className="py-4">
        Ao adquirir o sistema VÍDEO RECADOS o contratante declara estar ciente
        que:
      </p>

      <ul className="list-disc list-inside space-y-1 pl-3 text-justify">
        <li>
          O contratante é responsável por providenciar uma conexão de internet
          adequada no local do evento. A Cápsula do Tempo Eventos não se
          responsabiliza por falhas no funcionamento do sistema decorrentes de
          ausência ou falha de sinal de internet.
        </li>
        <li>
          A qualidade de imagem e som dos vídeos depende do aparelho utilizado
          pelos convidados e da qualidade do sinal de internet no momento da
          gravação. A Cápsula do Tempo Eventos não se responsabiliza por
          quaisquer deficiências ou variações na qualidade final dos vídeos
          entregues.
        </li>
        <li>
          Determinados aparelhos celulares ou suas configurações podem limitar
          ou impedir o funcionamento adequado do sistema de vídeo recados. A
          Cápsula do Tempo Eventos não se responsabiliza por limitações ou
          problemas decorrentes de configurações ou incompatibilidades dos
          dispositivos dos convidados.
        </li>
        <li>
          Ao utilizar a opção de upload de arquivos da galeria, os arquivos são
          enviados em sua qualidade original, o que pode ocasionar lentidão,
          especialmente com arquivos maiores. Alguns aparelhos podem dar a opção
          de gravar vídeos/tirar fotos na hora, de acordo com configurações
          próprias do aparelho, porém esses poderão ter sua qualidade reduzida.
        </li>
        <li>
          Os arquivos enviados em formato original incluem as seguintes
          extensões, mas não se limitando a, mp4, .mov, .jpg, . jpeg, .heic. É
          de responsabilidade do usuário ter disponível em seu
          computador/celular/aparelho um programa que permita visualizar essas
          extensões. Também fornecemos a opção de converter os arquivos, para
          jpg e mp4, podendo implicar na redução da qualidade dos arquivos.
        </li>
        <li>
          O sistema de gravação ficará disponível para o recebimento de vídeos a
          partir de sua data de criação e será encerrado automaticamente 7 dias
          corridos após a data do evento. Após esse período, o upload de novos
          arquivos será impossibilitado.
        </li>
        <li>
          O contratante receberá via email o link de acesso à nossa plataforma,
          onde poderá acessar o link do seu evento, fazer o download o QR code e
          modelos de cartões com o QR code, sendo de responsabilidade do
          contratante imprimir o QR code e disponibilizar aos convidados no
          evento.
        </li>
        <li>
          A Cápsula do Tempo Eventos não envia nenhum produto físico, apenas
          produtos digitais.
        </li>
        <li>
          O contratante receberá os arquivos da data escolhida pelo mesmo ao
          preencher o formulário de cadastro inicial.
        </li>
        <li>
          É de responsabilidade do contratante informar à Cápsula do Tempo
          Eventos caso haja uma alteração no endereço de email para envio dos
          vídeos.
        </li>
        <li>
          O link para download dos arquivos será disponibilizado na plataforma
          ou entregue ao contratante via email, dependendo da data de entrega
          escolhida.
        </li>
        <li>
          A Cápsula do Tempo Eventos não acessará os arquivos, exceto em casos
          excepcionais de suporte solicitado pelo cliente, respeitando a Lei
          Geral de Proteção de Dados (LGPD). Não compartilharemos fotos e vídeos
          enviados pelos convidados, garantindo a privacidade dos envolvidos.
        </li>
        <li>
          A Cápsula do Tempo Eventos não compartilha informações cadastrais com
          terceiros, em conformidade com a Lei Geral de Proteção de Dados
          (LGPD).
        </li>
        <li>
          O contratante autoriza expressamente que a Cápsula do Tempo Eventos
          utilize e divulgue a tela inicial do sistema, contendo os nomes e
          fotos dos contratantes, assim como os materiais produzidos (ex.:
          cartões com QR Code), para fins de divulgação e promoção da empresa.
        </li>
        <li>
          Os vídeos ficarão disponíveis para download por um período de 6 meses
          a partir da data de disponibilização. Após esse prazo, os arquivos
          poderão ser removidos dos servidores, sendo de responsabilidade do
          contratante realizar o download e armazená-los em segurança.
        </li>
      </ul>

      <h1 className="text-center text-lg uppercase p-10">
        Perguntas frequentes - vídeo recados
      </h1>

      <ul className="list-disc list-inside space-y-1 pl-3 text-justify">
        <li>
          O que devo fazer caso haja uma alteração na data do evento?
          <ul className="list-disc list-inside space-y-1 pl-8 text-justify mt-1">
            <li>
              Notifique a Cápsula do Tempo Eventos via WhatsApp (41) 98479-4770
              sobre a alteração da data no sistema com, no mínimo, 1 mês de
              antecedência em relação à data do evento. Essa notificação é
              essencial para garantir a atualização correta do serviço.
            </li>
          </ul>
        </li>
        <li className="pt-3">
          Como funciona em caso de cancelamento?
          <ul className="list-disc list-inside space-y-1 pl-8 text-justify mt-1">
            <li>
              Em caso de desistência da compra dentro de 7 dias, o contratante
              terá direito à devolução de 100% do valor, conforme indicado no
              artigo 49 do Código de Defesa do Consumidor (CDC).
            </li>
            <li>
              Se o pedido de cancelamento da compra for realizado até 15 dias
              corridos antes da data do evento, será retido o valor de R$ 100,00
              (cem reais) como taxa administrativa. Caso o pedido de
              cancelamento seja feito dentro dos 15 dias que antecedem o evento,
              não haverá devolução do valor.
            </li>
            <li>
              Independente do prazo de cancelamento, o QR Code do evento será
              desativado automaticamente e os arquivos já enviados à plataforma
              serão permanentemente excluídos.
            </li>
          </ul>
        </li>
      </ul>

      <p className="mt-56 text-xs text-justify">
        CONTRATADO(A): CAPSULA DO TEMPO EVENTOS LTDA , pessoa jurídica de
        direito privado com sede à Rua Laura Nunes Fernandes, 211, em São José
        dos Pinhais-PR, inscrita no CNPJ/MF sob n° 26.032.328/0001-83, neste ato
        representado por seu Sócio Diretor Patrick de César Francisco,
        brasileiro, casado, Engenheiro, portador do Documento de Identidade RG
        n° 8293441-3 SSP-PR e inscrito no CPF n° 060.302.639-78.
      </p>
    </div>
  );
};

export default ServiceTermsText;
