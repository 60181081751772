import { Play } from "../../assets/svg";
import { isVideo } from "utils/event";

const GalleryThumbnail = (props: any) => {
  const { item, imageProps } = props;

  const lastDotIndex = item.alt.lastIndexOf(".");
  const fileExt = item.alt.slice(lastDotIndex + 1);

  const { key, ...otherImageProps } = imageProps;

  if (isVideo(fileExt)) {
    return (
      <div key={key} className="w-full h-full relative">
        <img alt="" {...otherImageProps} />
        <div className="absolute inset-0 flex items-center justify-center cursor-pointer">
          <Play width={32} height={32} color="#fff" />
        </div>
      </div>
    );
  }

  return <img key={key} alt="" {...otherImageProps} />;
};

export default GalleryThumbnail;
