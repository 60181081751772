import React from "react";
import { Link, Outlet, useParams } from "react-router-dom";
import { clarity } from "react-microsoft-clarity";
import Footer from "./Footer";
import Logo from "../../assets/svg/Logo";
import { useTailwindScreenSize } from "utils/utils";
import clsx from "clsx";

type TUnauthenticatedLayoutProps = {
  showLeftImageInDesktop?: boolean;
};

export default function UnauthenticatedLayout(
  props: TUnauthenticatedLayoutProps
) {
  const { showLeftImageInDesktop = true } = props;
  const { eventCode } = useParams();

  const screenSize = useTailwindScreenSize();
  const isMobile = screenSize === "sm";
  const classes = clsx("grid grid-cols-1 grow", {
    "md:grid-cols-2": showLeftImageInDesktop,
  });

  if (process.env.NODE_ENV === "production") {
    clarity.init("ngjj6db2ll");
  }

  return (
    <React.StrictMode>
      <div className="w-full h-screen flex flex-col bg-rose-50">
        <div className={classes}>
          <div>
            <div className="w-full px-4">
              <Link to={`/public/${eventCode}/login`}>
                <Logo
                  width={isMobile ? 150 : 200}
                  height={isMobile ? 30 : 50}
                  color="#000000"
                />
              </Link>
            </div>
            <div>
              <Outlet />
            </div>
          </div>
          <div className="w-full h-full bg-[url('../../assets/images/bg-customer-login.png')] bg-cover bg-center hidden md:block"></div>
        </div>
        <Footer />
      </div>
    </React.StrictMode>
  );
}
