import { useState } from "react";
import { bingoChallengesSchema } from "assets/constants/bingoChallenges";
import { Button } from "../../components/ui";
import { useOutletContext } from "react-router-dom";
import { TEvent, TBingoChallenge } from "@services/types";
import { BINGO_FEATURE_FLAG } from "../../constants";
import { updateEvent } from "../../services/events";
import FeatureToggle from "./FeatureToggle";

const BINGO_CHALLENGES_REQUIRED = 25;

export default function Bingo() {
  const { event } = useOutletContext() as { event: TEvent };

  const featureEnabled = event.betaFeatures?.includes(BINGO_FEATURE_FLAG);
  const [isBingoEnabled, setIsBingoEnabled] = useState(
    featureEnabled as boolean
  );

  const currentActiveQuestions = event.bingo?.map((q) => q.id) ?? [];
  const [activeChallenges, setActiveQuestions] = useState<string[]>(
    currentActiveQuestions
  );

  const onBingoSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isBingoEnabled) {
      const bingoChallenges: TBingoChallenge[] = [];

      activeChallenges.forEach((challengeId) => {
        const challenge = bingoChallengesSchema.find(
          (q) => q.id === challengeId
        );

        if (!challenge) {
          console.error(`Challenge with id ${challengeId} not found!`);
          return;
        }

        bingoChallenges.push(challenge);
      });

      if (bingoChallenges.length !== BINGO_CHALLENGES_REQUIRED) {
        alert(
          `O bingo exige a seleção de ${BINGO_CHALLENGES_REQUIRED} desafios!`
        );
        return;
      }

      const betaFeatures = new Set(event.betaFeatures);
      betaFeatures.add(BINGO_FEATURE_FLAG);
      event.betaFeatures = Array.from(betaFeatures);
      event.bingo = bingoChallenges;
    } else {
      event.betaFeatures = event.betaFeatures?.filter(
        (feature) => feature !== BINGO_FEATURE_FLAG
      );
    }

    await updateEvent(event);
  };

  const handleQuestionToggle = (challengeId: string) => {
    setActiveQuestions((prev) => {
      if (prev.includes(challengeId)) {
        return prev.filter((id) => id !== challengeId);
      }
      if (prev.length >= BINGO_CHALLENGES_REQUIRED) {
        alert(
          `Você atingiu o limite máximo de ${BINGO_CHALLENGES_REQUIRED} desafios!`
        );
        return prev;
      }
      return [...prev, challengeId];
    });
  };

  return (
    <div className="flex flex-col items-start justify-start gap-4 w-full">
      <h1 className="w-full flex items-center gap-3">
        Bingo
        <FeatureToggle checked={isBingoEnabled} onChange={setIsBingoEnabled} />
      </h1>
      <div className="flex items-center gap-2">
        <h3 className="">
          {activeChallenges.length}/{BINGO_CHALLENGES_REQUIRED} desafios
          selecionados
        </h3>
      </div>
      <form
        className="flex flex-col justify-start gap-4 w-full"
        onSubmit={onBingoSubmit}
      >
        {bingoChallengesSchema.map((challenge, index) => (
          <div key={challenge.id} className="flex items-center gap-2">
            <input
              type="checkbox"
              className="h-6 w-6"
              checked={activeChallenges.includes(challenge.id)}
              onChange={() => handleQuestionToggle(challenge.id)}
              disabled={!isBingoEnabled}
            />
            <h3>{`${index + 1}. ${challenge.text}`}</h3>
          </div>
        ))}

        <div className="pt-5">
          <Button type="submit">Salvar</Button>
        </div>
      </form>
    </div>
  );
}
