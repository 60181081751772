import { TEvent } from "@services/types";
import eventBannerDefault from "../assets/images/event-banner-default.jpeg";
import { VIDEO_FILE_EXTENSIONS } from "../constants";

export const eventTitleFormatter = (event: TEvent) => {
  const { titleOneText, titleTwoText } = event.customization?.template || {};

  // White template has two titles
  if (event.customization?.template?.name === "wedding-white") {
    return `${titleOneText} & ${titleTwoText}`;
  }

  return titleOneText;
};

export const eventBanner = (event: TEvent) => {
  if (!event.bannerUrl) {
    return eventBannerDefault;
  }

  return event.bannerUrl;
};

export const isVideo = (fileExt: string) =>
  VIDEO_FILE_EXTENSIONS.includes(fileExt.toLowerCase());
