import Card from "components/ui/Card";
import { TContact, TEvent } from "../../services/types";
import { useLoaderData } from "react-router-dom";
import { eventBanner, eventTitleFormatter } from "utils/event";
import Button from "components/ui/Button";
import { useFieldArray, useForm } from "react-hook-form";
import {
  InputCheckbox,
  InputCheckboxGroup,
  InputSelect,
  InputText,
  InternationalPhoneInput,
} from "components/ui/form";
import { useEffect, useMemo, useRef, useState } from "react";
import dayjs from "dayjs";
import weddingWhiteEventTheme from "../../assets/images/event-theme/wedding-white.png";
import weddingRoseEventTheme from "../../assets/images/event-theme/wedding-rose.png";
import birthdayPinkEventTheme from "../../assets/images/event-theme/birthday-pink.png";
import birthdayPurpleEventTheme from "../../assets/images/event-theme/birthday-purple.png";
import birthdayYellowEventTheme from "../../assets/images/event-theme/birthday-yellow.png";
import birthdayGreenEventTheme from "../../assets/images/event-theme/birthday-green.png";
import birthdayBlueEventTheme from "../../assets/images/event-theme/birthday-blue.png";
import eventBannerDefault from "../../assets/images/event-banner-default.jpeg";
import { Copy, LeftArrow, RightArrow, Share, Url } from "../../assets/svg";
import {
  updateEventForCustomer,
  uploadEventBanner,
} from "../../services/events";
import { Notification } from "components/ui";
import Modal from "components/ui/Modal";
import EventBannerResizer from "components/events/EventBannerResizer";

const EVENT_THEME_IMAGES = [
  weddingRoseEventTheme,
  weddingWhiteEventTheme,
  birthdayPinkEventTheme,
  birthdayPurpleEventTheme,
  birthdayYellowEventTheme,
  birthdayGreenEventTheme,
  birthdayBlueEventTheme,
];

const EVENT_THEMES = [
  "wedding-rose",
  "wedding-white",
  "birthday-pink",
  "birthday-purple",
  "birthday-yellow",
  "birthday-green",
  "birthday-blue",
];

export type TCustomerEventForm = {
  code: string;
  title: string;
  date: string;
  releaseDate: string;
  realtimeGallery: boolean;
  publicGallery: boolean;
  contacts: TContact[];
  city: string;
  state: string;
  country: string;
  bannerUrl: string;
  totalGuests: string;
  customization: {
    theme: {
      name: string;
    };
    template: {
      name: string;
      titleFontSize: string;
      titleFontFamily: string;
      titleOneText: string;
      titleTwoText: string;
      subtitleText: string;
    };
  };
  languages: string[];
};

const selectThemeName = (templateName: string) => {
  if (templateName === "wedding-rose") {
    return "rose";
  }

  return "white";
};

const EditEvent = () => {
  const event = useLoaderData() as TEvent;
  const eventTitle = eventTitleFormatter(event);
  const eventDateFormatted = dayjs(event.date).format("DD | MM | YYYY");
  const publicEventUrl = `https://evento.capsuladotempo.com?code=${event.code}`;
  const [bannerFile, setBannerFile] = useState<string>(eventBannerDefault);
  const [currentThemeIndex, setCurrentThemeIndex] = useState(0);
  const [showSuccessNotification, setShowSuccessNotification] = useState(false);
  const [newBannerFile, setNewBannerFile] = useState<File | null>(null);
  const [bannerUploadProgress, setBannerUploadProgress] = useState(-1);
  const bannerInputRef = useRef<HTMLInputElement>(null);

  const defaultCustomization = useMemo(() => {
    const { theme, template } = event?.customization || {};

    return {
      theme,
      template: {
        name: template?.name,
        titleFontSize: template?.titleFontSize,
        titleFontFamily: template?.titleFontFamily,
        titleOneText: eventTitle,
        titleTwoText: template?.titleTwoText,
        subtitleText:
          template?.subtitleText ||
          "Grave sua mensagem e compartilhe suas fotos e vídeos da festa!",
      },
    };
  }, [event, eventTitle]);

  const languagesList = useMemo(() => {
    return [
      { label: "Português", value: "pt-BR" },
      { label: "Inglês", value: "en-US" },
      { label: "Espanhol", value: "es-ES" },
      { label: "Francês", value: "fr-FR" },
    ];
  }, []);

  const contactsEditable = useMemo(
    () => event.contacts?.filter((c) => c.notify === "true"),
    [event.contacts]
  );

  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
    setValue,
    getValues,
  } = useForm<TCustomerEventForm>({
    defaultValues: {
      code: event.code,
      title: event.title,
      date: event.date,
      releaseDate: event.releaseDate,
      realtimeGallery: event.realtimeGallery,
      publicGallery: event.publicGallery || false,
      contacts: event.contacts,
      city: event.city,
      state: event.state,
      country: event.country,
      totalGuests: event.totalGuests,
      languages: event.languages,
      customization: defaultCustomization,
    },
  });

  const { fields: contactFields } = useFieldArray({
    control,
    name: "contacts",
  });

  const onEventUrlClick = () => {
    const a = document.createElement("a");
    a.href = publicEventUrl;
    a.target = "_blank";
    a.click();
  };

  const onCopyEventUrlClick = () => {
    navigator.clipboard.writeText(publicEventUrl);
  };

  const onShareEventUrlClick = () => {
    if (navigator.share) {
      navigator.share({
        title: eventTitle,
        text: "Compartilhe seus momentos favoritos da festa!",
        url: publicEventUrl,
      });
    }
  };

  const validateEventTitle = (value: string) => {
    const template = getValues("customization.template.name");
    if (template === "wedding-white" && value.split("&").length !== 2) {
      return "Deve conter o nome dos noivos separados por '&'";
    }

    return true;
  };

  const handleBannerInputChangeClick = () => bannerInputRef.current?.click();

  const handleBannerInputChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = e.target.files?.[0];

    if (file) {
      setNewBannerFile(file);
    }
  };

  const handleBannerUpload = async (file: File) => {
    setNewBannerFile(null);

    const bannerUrl = await uploadEventBanner(
      event as TEvent,
      file,
      setBannerUploadProgress
    );

    setValue("bannerUrl", bannerUrl);

    const fileReader = new FileReader();

    fileReader.onload = () => {
      setBannerFile(fileReader.result as string);
    };
    fileReader.readAsDataURL(file);

    setBannerUploadProgress(-1);
  };

  const handleBannerModalClose = () => setNewBannerFile(null);

  const handlePreviousTheme = () => {
    const previousThemeIndex =
      currentThemeIndex === 0 ? EVENT_THEMES.length - 1 : currentThemeIndex - 1;

    setCurrentThemeIndex(previousThemeIndex);
  };

  const handleNextTheme = () => {
    const nextThemeIndex =
      currentThemeIndex === EVENT_THEMES.length - 1 ? 0 : currentThemeIndex + 1;

    setCurrentThemeIndex(nextThemeIndex);
  };

  const handleSave = handleSubmit(async (data) => {
    if (data.customization.template.name === "wedding-white") {
      const titleOneText = data.customization.template.titleOneText;
      const titleParts = titleOneText.split("&");
      data.customization.template.titleOneText = titleParts[0];
      data.customization.template.titleTwoText = titleParts[1];
    }

    await updateEventForCustomer(event.id as string, data);

    setShowSuccessNotification(true);
  });

  useEffect(() => {
    if (event) {
      setBannerFile(eventBanner(event));

      if (event.customization?.template?.name) {
        setCurrentThemeIndex(
          EVENT_THEMES.indexOf(event.customization.template.name)
        );
      }
    }
  }, [event]);

  useEffect(() => {
    const template = EVENT_THEMES[currentThemeIndex];
    setValue("customization.template.name", template);

    const themeName = selectThemeName(template);
    setValue("customization.theme.name", themeName);
  }, [setValue, currentThemeIndex]);

  return (
    <form
      autoComplete="off"
      noValidate={true}
      className="w-full h-full px-4 mb-20 md:px-0"
      onSubmit={handleSave}
    >
      <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-10">
        <div className="md:hidden">
          <div className="relative">
            <img
              src={bannerFile}
              alt={eventTitle}
              className="w-full rounded-lg object-cover shadow-lg"
            />
            <div className="absolute left-2 bottom-2 text-white">
              <h2 className="text-xl font-playfair-display font-normal">
                {eventTitle}
              </h2>
              <span className="text-sm">{eventDateFormatted}</span>
            </div>
          </div>
        </div>

        <div className="hidden md:flex md:gap-4">
          <div className="flex items-center justify-center">
            <img
              src={bannerFile}
              alt={eventTitle}
              className="w-28 h-28 rounded-full object-cover border-4 border-white"
            />
          </div>
          <div className="flex flex-col justify-center items-start">
            <h2 className="text-3xl font-playfair-display font-normal">
              {eventTitle}
            </h2>
            <span className="text-lg">{eventDateFormatted}</span>
          </div>
        </div>

        <Card className="p-6 flex flex-col gap-1">
          <span className="text-md font-century-gothic-bold text-rose-400">
            Link do evento:
          </span>

          <div className="flex gap-2">
            <div className="text-sm text-gray-500 flex items-center grow border border-red-400 p-1 truncate">
              {publicEventUrl}
            </div>

            <Button onClick={onEventUrlClick} padding="px-2">
              <Url width={24} height={24} outline="white" />
            </Button>

            <Button onClick={onCopyEventUrlClick} padding="px-2">
              <Copy width={24} height={24} color="white" />
            </Button>

            <Button onClick={onShareEventUrlClick} padding="px-2">
              <Share width={24} height={24} outline="white" />
            </Button>
          </div>

          <span className="text-sm">
            Compartilhe esse link com seus convidados!
          </span>
        </Card>

        <Card className="p-6">
          <fieldset className="flex flex-col gap-3">
            <legend className="w-full text-2xl font-century-gothic-bold flex justify-between items-center mb-4">
              Dados do Evento
            </legend>

            <InputText
              label="Nome do evento"
              register={register("customization.template.titleOneText", {
                required: "Obrigatório",
                validate: validateEventTitle,
              })}
              field="customization.template.titleOneText"
              type="text"
              error={errors.customization?.template?.titleOneText}
            />

            <InputText
              label="Data do Evento"
              register={register("date", {
                required: "Obrigatório",
              })}
              field="date"
              type="date"
              error={errors.date}
              disabled={true}
              hint="Caso precise alterar a data do seu evento, entre em contato com o suporte pelo Whatsapp."
            />

            <InputText
              label="Número de convidados"
              register={register("totalGuests")}
              field="totalGuests"
              type="number"
              error={errors.totalGuests}
            />

            <InputCheckbox
              label="Tornar galeria pública"
              register={register("publicGallery")}
              field="publicGallery"
              hint="Seus convidados poderão ver e baixar as fotos do evento."
              error={errors.publicGallery}
            />
          </fieldset>
        </Card>

        <Card className="p-6">
          <fieldset className="h-full flex flex-col gap-3">
            <legend className="w-full text-2xl font-century-gothic-bold flex justify-between items-center">
              Dados do Anfitrião
            </legend>

            <div className="grow divide-y divide-gray-300 flex flex-col gap-3">
              {contactFields.map(
                (contact, index) =>
                  contactsEditable?.some((c) => c.email === contact.email) && (
                    <div
                      key={contact.id}
                      className="w-full flex flex-col items-start gap-3 pt-3"
                    >
                      <InputText
                        label="Nome completo"
                        register={register(`contacts.${index}.name`)}
                        field={`contacts[${index}]name`}
                        type="text"
                        error={errors.contacts?.[index]?.name}
                      />

                      <InputSelect
                        label="Tipo de usuário"
                        register={register(`contacts.${index}.reference`)}
                        field={`contacts.${index}.reference`}
                        options={[
                          { label: "Noivo", value: "Noivo" },
                          { label: "Noiva", value: "Noiva" },
                          {
                            label: "Aniversariante",
                            value: "Aniversariante",
                          },
                          {
                            label: "Mãe/pai do(a) aniversariante",
                            value: "Mãe/pai do(a) aniversariante",
                          },
                          { label: "Formando", value: "Formando" },
                          { label: "Formanda", value: "Formanda" },
                          { label: "Outro", value: "Outro" },
                        ]}
                        error={errors.contacts?.[index]?.reference}
                      />

                      <InputText
                        label="Email"
                        register={register(`contacts.${index}.email`)}
                        field={`contacts.${index}.email`}
                        type="email"
                        error={errors.contacts?.[index]?.email}
                        isEditable={false}
                        disabled
                        hint="Esse é o email que receberá o link para baixar os vídeos"
                      />

                      <InternationalPhoneInput
                        label="Whatsapp"
                        field={`contacts.${index}.whatsapp`}
                        error={errors.contacts?.[index]?.whatsapp}
                        isEditable={true}
                        control={control}
                      />
                    </div>
                  )
              )}
            </div>
          </fieldset>
        </Card>
      </div>

      <Card className="w-full mt-10 p-6">
        <fieldset className="w-full flex flex-col">
          <legend className="w-full text-2xl font-century-gothic-bold flex justify-between items-center mb-4">
            Layout da página
          </legend>

          <div className="grid grid-cols-1 md:grid-cols-2">
            <div className="flex flex-col gap-3 md:border-r md:border-gray-300 md:pr-6">
              <div className="flex flex-col gap-2">
                <input
                  type="file"
                  ref={bannerInputRef}
                  id="bannerFile"
                  className="hidden"
                  onChange={handleBannerInputChange}
                  accept="image/*"
                />

                <label
                  htmlFor="bannerFile"
                  className="flex flex-col gap-1.5 text-xs font-century-gothic-bold"
                >
                  Imagem da capa
                  <div className="flex gap-2 items-end">
                    <div className="relative w-40 h-40 rounded-lg bg-gray-300">
                      {bannerUploadProgress !== -1 ? (
                        <progress
                          value={bannerUploadProgress}
                          max="100"
                          className="absolute inset-0 m-auto w-3/4"
                        ></progress>
                      ) : (
                        <img
                          alt="Banner do Evento"
                          src={bannerFile}
                          className="w-40 h-40 object-cover rounded-lg"
                        />
                      )}
                    </div>

                    <Button
                      className="w-[140px]"
                      onClick={handleBannerInputChangeClick}
                    >
                      Alterar
                    </Button>
                  </div>
                </label>
              </div>

              <InputText
                label="Frase da tela inicial"
                register={register("customization.template.subtitleText")}
                field="customization.template.subtitleText"
                type="text"
                error={errors.customization?.template?.subtitleText}
              />

              <div className="">
                <InputCheckboxGroup
                  register={register(`languages`)}
                  label={"Idiomas"}
                  description="Selecione os idiomas para o seu evento (mín 2 idiomas):"
                  field={`languages`}
                  options={languagesList}
                  error={errors.languages?.message}
                />
              </div>
            </div>

            <div className="w-full px-2 flex flex-col gap-2 md:ml-6">
              <label className="flex items-center gap-1.5 text-xs font-century-gothic-bold">
                Layout
              </label>

              <span>Selecione o layout para a tela inicial do seu evento:</span>

              <div className="md:pr-10">
                <div className="relative overflow-hidden">
                  <div
                    className="flex transition-transform duration-700 ease-in-out"
                    style={{
                      transform: `translateX(-${currentThemeIndex * 100}%)`,
                    }}
                  >
                    {EVENT_THEME_IMAGES.map((theme, index) => (
                      <div
                        key={index}
                        className="w-full flex justify-center flex-shrink-0"
                      >
                        <img
                          src={theme}
                          className="w-80 h-80 object-cover"
                          alt={`Tema ${index}`}
                        />
                      </div>
                    ))}
                  </div>
                  <button
                    type="button"
                    className="absolute top-1/2 left-5 md:left-20"
                    onClick={handlePreviousTheme}
                  >
                    <LeftArrow
                      width={24}
                      height={24}
                      color="#F76C6F"
                      outline="#F76C6F"
                    />
                  </button>
                  <button
                    type="button"
                    className="absolute top-1/2 right-5 md:right-20"
                    onClick={handleNextTheme}
                  >
                    <RightArrow
                      width={24}
                      height={24}
                      color="#F76C6F"
                      outline="#F76C6F"
                    />
                  </button>
                </div>

                <div className="hidden md:block mt-5">
                  <div className="flex justify-between">
                    {EVENT_THEMES.map((theme, index) => (
                      <button
                        key={index}
                        type="button"
                        onClick={() => setCurrentThemeIndex(index)}
                      >
                        <img
                          src={EVENT_THEME_IMAGES[index]}
                          alt={`Tema ${index}`}
                          className="w-20 h-20 object-cover"
                        />
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </Card>

      <div className="w-full flex items-center justify-center mt-10">
        <Button type="submit" className="w-[140px]">
          Salvar
        </Button>
      </div>

      <Modal isOpen={newBannerFile != null} onClose={handleBannerModalClose}>
        <div className="w-screen px-5">
          <EventBannerResizer
            file={newBannerFile}
            onFinish={handleBannerUpload}
            onCancel={handleBannerModalClose}
          />
        </div>
      </Modal>

      <Notification
        open={showSuccessNotification}
        setOpen={setShowSuccessNotification}
      >
        Alterações salvas com sucesso!
      </Notification>
    </form>
  );
};

export default EditEvent;
