import { TEvent } from "@services/types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import QRCode from "components/ui/QRCode";
import { useOutletContext } from "react-router-dom";
import { toPng } from "html-to-image";
import Button from "components/ui/Button";
import {
  getCardLink,
  sendReleaseEmailRequest,
  sendCreateAccountEmail,
} from "services/events";
import * as uploadsService from "services/uploads";
import dayjs from "dayjs";

export default function EventActions() {
  const { event } = useOutletContext() as { event: TEvent };
  const [isCopied, setIsCopied] = React.useState(false);
  const [isCustomerEventLinkCopied, setIsCustomerEventLinkCopied] =
    React.useState(false);
  const timerRef = React.useRef<NodeJS.Timeout>();
  const qrCodeRef = React.useRef<HTMLDivElement>(null);
  const largeQrCodeRef = React.useRef<HTMLDivElement>(null);
  const [isReleaseEmailSent, setIsSentReleaseEmailSent] = useState(false);
  const [isCreateAccountEmailSent, setCreateAccountEmailSent] = useState(false);
  const [eventUploadsCount, setEventUploadsCount] = useState(0);

  const eventRecordLink = `https://evento.capsuladotempo.com/?code=${event.code}`;
  const customerEventSettingsLink = `https://app.capsuladotempo.com/public/${event.code}/new-account`;
  const downloadLink = useMemo(() => {
    if (eventUploadsCount === 0) {
      return `https://app.capsuladotempo.com/download/${event.id}?email=shared_directly`;
    }

    return `https://app.capsuladotempo.com/download/upload/${event.id}?email=shared_directly`;
  }, [event, eventUploadsCount]);

  const defaultTextOne = `
Oii!!
Tudo bom?

Esse é o link do seu evento:
${eventRecordLink}

Você já pode enviar fotos e vídeos para testar!

Esse link você pode também compartilhar com os convidados, para que eles também possam enviar lembranças para você!

E te enviei por email as orientações de como acessar a plataforma, alterar foto da capa e baixar seu QR code e muito mais!!

Qualquer dúvida, é só chamar que estou aqui
Um abraço!
  `;
  const [textOne, setTextOne] = React.useState(`${defaultTextOne}`);

  const qrCodeSize = 500;
  const largeQrCodeSize = 1000;

  const sendReleaseEmailButtonDisabled = useMemo(() => {
    const contactToNotifyFound = event.contacts?.some(
      (c) => c.email && c.notify === "true"
    );

    const now = dayjs();
    const releaseDate = dayjs(event.releaseDate);
    const eventNotReleased = releaseDate.isAfter(now);

    return !contactToNotifyFound || eventNotReleased;
  }, [event]);

  const copyLink = useCallback(() => {
    navigator.clipboard.writeText(eventRecordLink);
    setIsCopied(true);
    timerRef.current = setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  }, [eventRecordLink]);

  const copyCustomerEventLink = useCallback(() => {
    navigator.clipboard.writeText(customerEventSettingsLink);
    setIsCustomerEventLinkCopied(true);
    timerRef.current = setTimeout(() => {
      setIsCustomerEventLinkCopied(false);
    }, 3000);
  }, [customerEventSettingsLink]);

  const copyDownloadLink = useCallback(() => {
    navigator.clipboard.writeText(downloadLink);
    setIsCopied(true);
    timerRef.current = setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  }, [downloadLink]);

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  const downloadQRCode = useCallback(async () => {
    if (qrCodeRef.current === null) return;
    try {
      const dataUrl = await toPng(qrCodeRef.current, {
        cacheBust: true,
        canvasHeight: qrCodeSize,
        canvasWidth: qrCodeSize,
        width: qrCodeSize,
        height: qrCodeSize,
        skipFonts: true,
      });
      const link = document.createElement("a");
      link.download = `QR Code ${event.title}.png`;
      link.href = dataUrl;
      link.click();
    } catch (error) {
      console.error(error);
    }
  }, [event.title]);

  const downloadLargeQRCode = useCallback(async () => {
    if (largeQrCodeRef.current === null) return;
    try {
      const dataUrl = await toPng(largeQrCodeRef.current, {
        cacheBust: true,
        canvasHeight: largeQrCodeSize,
        canvasWidth: largeQrCodeSize,
        width: largeQrCodeSize,
        height: largeQrCodeSize,
        skipFonts: true,
      });
      const link = document.createElement("a");
      link.download = `QR Code ${event.title}.png`;
      link.href = dataUrl;
      link.click();
    } catch (error) {
      console.error(error);
    }
  }, [event.title]);

  const downloadPDF = useCallback(
    async (cardModel: string) => {
      const url = getCardLink(cardModel, event, eventRecordLink, "pdf");
      window.location.href = url;
    },
    [event, eventRecordLink]
  );

  const downloadPNG = useCallback(
    async (cardModel: string) => {
      const url = getCardLink(cardModel, event, eventRecordLink, "png");
      window.location.href = url;
    },
    [event, eventRecordLink]
  );

  const handleSendCreateAccountEmailClick = useCallback(async () => {
    try {
      await sendCreateAccountEmail(event.code as string);
      setCreateAccountEmailSent(true);
      setTimeout(() => setCreateAccountEmailSent(false), 3000);
    } catch (error) {
      console.error(error);
    }
  }, [event]);

  const handleSendReleaseEmailClick = useCallback(async () => {
    try {
      await sendReleaseEmailRequest(event.code as string);
      setIsSentReleaseEmailSent(true);
      setTimeout(() => setIsSentReleaseEmailSent(false), 3000);
    } catch (error) {
      console.error(error);
    }
  }, [event]);

  useEffect(() => {
    const fetchUploadsCount = async () => {
      const count = await uploadsService.countUploadsByEvent(
        event.id as string
      );
      setEventUploadsCount(count);
    };

    fetchUploadsCount();
  }, [event]);

  return (
    <div className="w-full flex flex-col justify-start space-y-20 p-10 bg-white">
      <div className="flex">
        <section className="">
          <h2 className="mb-3">QR Code</h2>
          <div className="flex items-center justify-start gap-10">
            <div className="hidden">
              <div ref={qrCodeRef}>
                <QRCode link={eventRecordLink} size={qrCodeSize} />
              </div>
              <div ref={largeQrCodeRef}>
                <QRCode link={eventRecordLink} size={largeQrCodeSize} />
              </div>
            </div>

            <QRCode link={eventRecordLink} />

            <div className="flex flex-col items-start justify-start gap-2">
              <a href={eventRecordLink} target="_blank" rel="noreferrer">
                {eventRecordLink}
              </a>
              <button
                type="button"
                onClick={copyLink}
                className="button border border-red-400 text-red-400"
              >
                {isCopied ? "Copiado" : "Copiar link"}
              </button>

              <a
                href="https://app.capsuladotempo.com/download/upload/VY8zpLMhUaS4q2f70S2w?email=shared_directly"
                target="_blank"
                rel="noreferrer"
              >
                {eventRecordLink}
              </a>

              <button
                type="button"
                onClick={downloadQRCode}
                className="button bg-red-400 text-white"
              >
                Download QR Code 500x500
              </button>
              <button
                type="button"
                onClick={downloadLargeQRCode}
                className="button bg-red-400 text-white"
              >
                Download QR Code 1000x1000
              </button>

              <button
                type="button"
                onClick={() => downloadPDF("model1")}
                className="button bg-red-400 text-white"
              >
                Download PDF 10,5x14,5cm (Grave a sua mensagem)
              </button>

              <button
                type="button"
                onClick={() => downloadPNG("model1")}
                className="button bg-red-400 text-white"
              >
                Download PNG 794×1118 (Grave a sua mensagem)
              </button>

              <button
                type="button"
                onClick={() => downloadPDF("model2")}
                className="button bg-red-400 text-white"
              >
                Download PDF 10,5x14,5cm (Compartilhe momentos)
              </button>

              <button
                type="button"
                onClick={() => downloadPNG("model2")}
                className="button bg-red-400 text-white"
              >
                Download PNG 794×1118 (Compartilhe momentos)
              </button>

              <button
                type="button"
                onClick={() => downloadPDF("model3")}
                className="button bg-red-400 text-white"
              >
                Download PDF 10,5x14,5cm (Cápsula do Tempo)
              </button>

              <button
                type="button"
                onClick={() => downloadPNG("model3")}
                className="button bg-red-400 text-white"
              >
                Download PNG 794×1118 (Cápsula do Tempo)
              </button>
            </div>
          </div>
        </section>

        <section className="flex-none max-w-[300px]">
          <h2 className="mb-3">Outras ações</h2>

          <div className="flex items-center justify-start gap-10">
            <div className="flex flex-col items-start justify-start gap-2">
              <Button
                type="button"
                className="text-white"
                disabled={isCreateAccountEmailSent}
                disabledColor={
                  isReleaseEmailSent ? "bg-green-500" : "bg-gray-300"
                }
                onClick={handleSendCreateAccountEmailClick}
              >
                {isCreateAccountEmailSent
                  ? "Email enviado"
                  : "Enviar email para criação de conta"}
              </Button>
            </div>
          </div>

          <div className="flex items-center justify-start gap-10">
            <div className="flex flex-col items-start justify-start gap-2">
              <Button
                type="button"
                className="text-white"
                disabled={isReleaseEmailSent || sendReleaseEmailButtonDisabled}
                disabledColor={
                  isReleaseEmailSent ? "bg-green-500" : "bg-gray-300"
                }
                onClick={handleSendReleaseEmailClick}
              >
                {isReleaseEmailSent
                  ? "Email enviado"
                  : "Entregar vídeos por email"}
              </Button>
            </div>
          </div>
        </section>
      </div>

      <section className="">
        <h2 className="mb-3">Link para o painel do cliente (Área logada)</h2>
        <div className="flex items-center justify-start gap-10">
          <div className="flex flex-col items-start justify-start gap-2">
            <a
              href={customerEventSettingsLink}
              target="_blank"
              rel="noreferrer"
            >
              {customerEventSettingsLink}
            </a>
            <button
              type="button"
              onClick={copyCustomerEventLink}
              className="button border border-red-400 text-red-400"
            >
              {isCustomerEventLinkCopied ? "Copiado" : "Copiar link"}
            </button>
          </div>
        </div>

        <h2 className="mb-3">Link para Download - Usuário</h2>
        <div className="flex items-center justify-start gap-10">
          <div className="flex flex-col items-start justify-start gap-2">
            <a href={downloadLink} target="_blank" rel="noreferrer">
              {downloadLink}
            </a>
            <button
              type="button"
              onClick={copyDownloadLink}
              className="button border border-red-400 text-red-400"
            >
              {isCopied ? "Copiado" : "Copiar link"}
            </button>
          </div>
        </div>
      </section>
      <section className="">
        <h2>Whatsapp</h2>
        <div className="w-full flex items-start justify-start gap-2 pb-20">
          <div className="flex flex-1 flex-col gap-5">
            <div className="h-5"></div>
            <div className="h-60">
              <textarea
                className="w-full h-full"
                value={textOne}
                onChange={(e) => setTextOne(e.target.value)}
              />
            </div>
            <div className="h-10"></div>
          </div>
          {event?.contacts?.map((contact, idx) => (
            <div
              className="flex flex-col items-center justify-start gap-5 max-w-[120px]"
              key={idx}
            >
              <div className="h-5 truncate max-w-[120px]">{contact.name}</div>
              <div className="h-60">
                <a
                  href={`https://wa.me/${
                    contact.whatsapp
                  }/?text=${encodeURIComponent(textOne)}`}
                  target="_blank"
                  rel="noreferrer"
                  className="button bg-green-500 text-white"
                >
                  Whatsapp{" "}
                </a>
              </div>
              <div className="h-10 flex flex-col max-w-[120px]">
                <a
                  href={`https://wa.me/${contact.whatsapp}`}
                  target="_blank"
                  rel="noreferrer"
                  className="max-w-[120px] truncate"
                >
                  {contact.whatsapp}
                </a>
                <a
                  href={`https://instagram.com/${contact.instagram}`}
                  target="_blank"
                  rel="noreferrer"
                  className="max-w-[120px] truncate"
                >
                  {contact.instagram}
                </a>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}
