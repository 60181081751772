import { useState, useRef, useMemo } from "react";
import { quizQuestionsSchema } from "assets/constants/quizQuestions";
import { TEvent, TQuizQuestion } from "@services/types";
import { useOutletContext } from "react-router-dom";
import { updateEvent } from "../../services/events";
import { QUIZ_FEATURE_FLAG } from "../../constants";
import { Button } from "../ui";
import FeatureToggle from "./FeatureToggle";

const QUIZ_QUESTIONS_LIMIT = 15;

export default function Quiz() {
  const { event } = useOutletContext() as { event: TEvent };

  const featureEnabled = event.betaFeatures?.includes(QUIZ_FEATURE_FLAG);
  const [isSuperQuizEnabled, setIsSuperQuizEnabled] = useState(
    featureEnabled as boolean
  );

  const currentActiveQuestions = event.quiz?.map((q) => q.id) ?? [];
  const [activeQuestions, setActiveQuestions] = useState<string[]>(
    currentActiveQuestions
  );

  const quizQuestions = useMemo(() => {
    return quizQuestionsSchema.map((questionSchema) => {
      const quizQuestion = event.quiz?.find((q) => q.id === questionSchema.id);

      if (!quizQuestion) {
        return {
          ...questionSchema,
          correct: "",
        };
      }

      const { correct, options } = quizQuestion;

      const wrongOptions = options.filter((option) => option !== correct);

      return {
        ...questionSchema,
        wrongOptions,
        correct,
      };
    });
  }, [event.quiz]);

  const onSuperQuizSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (isSuperQuizEnabled) {
      const quiz: TQuizQuestion[] = [];

      activeQuestions.forEach((questionId) => {
        const question = quizQuestions.find((q) => q.id === questionId);

        if (!question) {
          console.error(`Question with id ${questionId} not found!`);
          return;
        }

        // fetch the inputs
        const correctInput = e.currentTarget.elements.namedItem(
          `${questionId}_correct`
        ) as HTMLInputElement;
        const wrong1Input = e.currentTarget.elements.namedItem(
          `${questionId}_wrong_1`
        ) as HTMLInputElement;
        const wrong2Input = e.currentTarget.elements.namedItem(
          `${questionId}_wrong_2`
        ) as HTMLInputElement;
        const wrong3Input = e.currentTarget.elements.namedItem(
          `${questionId}_wrong_3`
        ) as HTMLInputElement;

        const options = [
          correctInput.value,
          wrong1Input.value,
          wrong2Input.value,
          wrong3Input.value,
        ];

        quiz.push({
          id: question.id,
          text: question.text,
          options,
          correct: options[0],
        });
      });

      const betaFeatures = new Set(event.betaFeatures);
      betaFeatures.add(QUIZ_FEATURE_FLAG);

      event.betaFeatures = Array.from(betaFeatures);
      event.quiz = quiz;
    } else {
      event.betaFeatures = event.betaFeatures?.filter(
        (f) => f !== QUIZ_FEATURE_FLAG
      );
    }

    // Save quiz
    await updateEvent(event);
  };

  const handleQuestionToggle = (questionId: string) => {
    setActiveQuestions((prev) => {
      if (prev.includes(questionId)) {
        return prev.filter((id) => id !== questionId);
      }
      if (prev.length >= QUIZ_QUESTIONS_LIMIT) {
        alert(
          `Você atingiu o limite máximo de ${QUIZ_QUESTIONS_LIMIT} questões!`
        );
        return prev;
      }
      return [...prev, questionId];
    });
  };

  return (
    <div className="flex flex-col items-start justify-start gap-4 w-full">
      <h1 className="w-full flex items-center gap-3">
        Quiz
        <FeatureToggle
          checked={isSuperQuizEnabled}
          onChange={setIsSuperQuizEnabled}
        />
      </h1>

      <div className="flex items-center gap-2">
        <h3 className="">
          {activeQuestions.length}/{QUIZ_QUESTIONS_LIMIT} questões selecionadas
        </h3>
      </div>
      <form
        className="flex flex-col justify-start gap-4 w-full"
        onSubmit={onSuperQuizSubmit}
      >
        {quizQuestions.map((question, index) => (
          <QuizQuestion
            key={question.id}
            index={index + 1}
            id={question.id}
            setActiveQuestions={handleQuestionToggle}
            isActive={activeQuestions.includes(question.id)}
            question={question.text}
            wrongOptions={question.wrongOptions}
            correctOption={question.correct}
            disabled={!isSuperQuizEnabled}
          />
        ))}

        <div className="pt-5">
          <Button type="submit">Salvar</Button>
        </div>
      </form>
    </div>
  );
}

const QuizQuestion = ({
  id,
  question,
  wrongOptions,
  correctOption,
  index,
  setActiveQuestions,
  isActive,
  disabled = false,
}: {
  id: string;
  question: string;
  wrongOptions: string[];
  correctOption: string;
  index: number;
  setActiveQuestions: (questionId: string) => void;
  isActive: boolean;
  disabled?: boolean;
}) => {
  // Check if this state is working correctly
  const [usedWrongOptions, setUsedWrongOptions] = useState(() =>
    [...wrongOptions].sort(() => Math.random() - 0.5).slice(0, 3)
  );
  // Add refs for the inputs
  const inputRefs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];

  // Add some debugging to see what's happening
  const getNewRandomOption = (currentOption: string) => {
    const unusedOptions = wrongOptions.filter(
      (option) => !usedWrongOptions.includes(option)
    );

    if (unusedOptions.length === 0) return currentOption;

    return unusedOptions[Math.floor(Math.random() * unusedOptions.length)];
  };

  const handleOptionClick = (index: number) => {
    const newOption = getNewRandomOption(usedWrongOptions[index]);
    setUsedWrongOptions((prev) => {
      const newOptions = [...prev];
      newOptions[index] = newOption;
      return newOptions;
    });

    // Update the input value directly
    const inputRef = inputRefs[index]?.current;
    if (inputRef) {
      inputRef.value = newOption;
    }
  };

  return (
    <fieldset
      className="flex flex-col justify-start gap-2 w-full"
      disabled={disabled}
    >
      <legend className="flex items-center justify-start gap-4 w-full">
        <input
          type="checkbox"
          checked={isActive}
          onChange={(e) => setActiveQuestions(id)}
          className="w-6 h-6"
          disabled={disabled}
        />
        <h2 className="font-bold">{`${index}. ${question}`}</h2>
      </legend>

      <div className="flex items-center justify-start gap-2 w-full">
        <div className="flex flex-col grow">
          <h3 className="font-bold">Resposta Correta</h3>
          <input
            type="text"
            name={`${id}_correct`}
            defaultValue={correctOption}
          />
        </div>
        <div className="flex flex-col grow">
          <h3
            className="cursor-pointer hover:text-red-400"
            onClick={() => handleOptionClick(0)}
          >
            Opção Errada 1
          </h3>{" "}
          <input
            ref={inputRefs[0]}
            type="text"
            name={`${id}_wrong_1`}
            defaultValue={usedWrongOptions[0]}
          />
        </div>
        <div className="flex flex-col grow">
          <h3
            className="cursor-pointer hover:text-red-400"
            onClick={() => handleOptionClick(1)}
          >
            Opção Errada 2
          </h3>
          <input
            ref={inputRefs[1]}
            type="text"
            name={`${id}_wrong_2`}
            defaultValue={usedWrongOptions[1]}
          />
        </div>
        <div className="flex flex-col grow">
          <h3
            className="cursor-pointer hover:text-red-400"
            onClick={() => handleOptionClick(2)}
          >
            Opção Errada 3
          </h3>
          <input
            ref={inputRefs[2]}
            type="text"
            name={`${id}_wrong_3`}
            defaultValue={usedWrongOptions[2]}
          />
        </div>
      </div>
    </fieldset>
  );
};
