export const bingoChallengesSchema = [
  {
    id: "challenge_1",
    text: "Tire uma foto com o pai da noiva",
  },
  {
    id: "challenge_2",
    text: "Tire uma foto com o pai do noivo",
  },
  {
    id: "challenge_3",
    text: "Tire uma foto com a mãe da noiva",
  },
  {
    id: "challenge_4",
    text: "Tire uma foto com a mãe do noivo",
  },
  {
    id: "challenge_5",
    text: "Tire uma foto com os noivos",
  },
  {
    id: "challenge_6",
    text: "Tire uma foto com um casal de padrinhos",
  },
  {
    id: "challenge_7",
    text: "Tire uma foto dançando",
  },
  {
    id: "challenge_8",
    text: "Tire uma foto com alguém que não conhecia antes",
  },
  {
    id: "challenge_9",
    text: "Tire uma foto com o buquê",
  },
  {
    id: "challenge_10",
    text: "Tire uma foto com três gerações juntas",
  },
  {
    id: "challenge_11",
    text: "Tire uma foto com as alianças",
  },
  {
    id: "challenge_12",
    text: "Tire uma foto com a decoração mais bonita",
  },
  {
    id: "challenge_13",
    text: "LOVE",
  },
  {
    id: "challenge_14",
    text: "Tire uma selfie com seu +1",
  },
  {
    id: "challenge_15",
    text: "Tire uma foto com as crianças da festa",
  },
  {
    id: "challenge_16",
    text: "Tire uma foto com os avós dos noivos",
  },
  {
    id: "challenge_17",
    text: "Tire uma foto com o bolo",
  },
  {
    id: "challenge_18",
    text: "Tire uma foto com os irmãos dos noivos",
  },
  {
    id: "challenge_19",
    text: "Tire uma foto do seu prato favorito",
  },
  {
    id: "challenge_20",
    text: "Tire uma foto com a mesa de doces",
  },
  {
    id: "challenge_21",
    text: "Tire uma foto do brinde",
  },
  {
    id: "challenge_22",
    text: "Tire uma foto com os músicos",
  },
  {
    id: "challenge_23",
    text: "Tire uma foto do seu sapato de dança",
  },
  {
    id: "challenge_24",
    text: "Tire uma foto com primos dos noivos",
  },
  {
    id: "challenge_25",
    text: "Tire uma foto do momento mais emocionante",
  },
  {
    id: "challenge_26",
    text: "Tire uma foto com o DJ",
  },
  {
    id: "challenge_27",
    text: "Tire uma foto com a pista de dança cheia",
  },
  {
    id: "challenge_28",
    text: "Tire uma foto com a noiva jogando o buquê",
  },
  {
    id: "challenge_29",
    text: "Tire uma foto com o noivo e seus amigos",
  },
  {
    id: "challenge_30",
    text: "Tire uma foto com a entrada dos noivos",
  },
  {
    id: "challenge_31",
    text: "Tire uma foto com a primeira dança dos noivos",
  },
  {
    id: "challenge_32",
    text: "Tire uma foto com a noiva se arrumando",
  },
  {
    id: "challenge_33",
    text: "Tire uma foto com o noivo se arrumando",
  },
  {
    id: "challenge_34",
    text: "Tire uma foto com a mesa dos noivos",
  },
  {
    id: "challenge_35",
    text: "Tire uma foto com a mesa dos padrinhos",
  },
  {
    id: "challenge_36",
    text: "Tire uma foto com a mesa dos pais dos noivos",
  },
  {
    id: "challenge_37",
    text: "Tire uma foto com a mesa dos amigos",
  },
  {
    id: "challenge_38",
    text: "Tire uma foto com a mesa dos colegas de trabalho",
  },
  {
    id: "challenge_39",
    text: "Tire uma foto com a mesa dos primos",
  },
  {
    id: "challenge_40",
    text: "Tire uma foto com a mesa dos tios",
  },
];
