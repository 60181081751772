export const MONTHS_UNTIL_CLOSE_DOWNLOADS = 6;

export const VIDEO_FILE_EXTENSIONS = ["mp4", "webm", "mov"];

export const BINGO_FEATURE_FLAG = "bingo";

export const QUIZ_FEATURE_FLAG = "quiz";

export const PHOTO_CHALLENGE_FEATURE_FLAG = "photo_challenge";

export const TEAM_MATCH_FEATURE_FLAG = "team_match";

export const UPLOADS_IN_MENU_FEATURE_FLAG = "uploads_in_menu";
