export const quizQuestionsSchema = [
  {
    id: "question_1",
    text: "Onde o casal se conheceu?",
    wrongOptions: [
      "no parque",
      "no cinema",
      "no restaurante",
      "na academia",
      "na escola",
      "na faculdade",
      "na praia",
      "na casa de amigos",
      "no shopping",
      "no trabalho",
    ],
  },
  {
    id: "question_2",
    text: "Em que ano o casal se conheceu?",
    wrongOptions: [
      "2008",
      "2009",
      "2010",
      "2011",
      "2012",
      "2013",
      "2014",
      "2015",
      "2016",
      "2017",
      "2018",
      "2019",
      "2020",
      "2021",
      "2022",
      "2023",
      "2024",
      "Este ano",
    ],
  },
  {
    id: "question_3",
    text: "Como foi o pedido de namoro?",
    wrongOptions: [
      "por mensagem de WhatsApp depois de uma conversa romântica",
      "durante um jantar à luz de velas em um restaurante italiano",
      "em uma viagem surpresa para a praia no fim de semana",
      "no aniversário dela durante a festa com amigos e família",
      "durante um piquenique preparado especialmente no parque",
      "após uma sessão de cinema com o filme favorito dela",
      "em uma caminhada ao pôr do sol na montanha",
      "durante um show da banda favorita do casal",
      "em uma viagem de final de ano para Nova York",
      "no local onde se conheceram pela primeira vez",
    ],
  },
  {
    id: "question_4",
    text: "Qual foi o primeiro presente?",
    wrongOptions: [
      "um livro",
      "um perfume",
      "um relógio",
      "um buquê de flores",
      "uma caixa de chocolates",
      "um porta-retrato",
      "uma pulseira",
      "um ursinho de pelúcia",
      "um colar",
      "uma carteira",
      "um par de brincos",
      "um álbum de fotos",
      "uma caneca personalizada",
      "um vale-presente",
      "uma cesta de café da manhã",
    ],
  },
  {
    id: "question_5",
    text: "Como foi o primeiro encontro?",
    wrongOptions: [
      "um jantar romântico em um restaurante italiano à luz de velas",
      "um passeio descontraído pelo parque seguido de um café especial",
      "uma sessão de cinema com pipoca e depois uma conversa agradável",
      "um show da banda favorita deles com direito a after party",
      "um piquenique surpresa preparado por ele no jardim botânico",
      "uma tarde divertida no shopping seguida de um jantar casual",
      "uma caminhada na praia ao pôr do sol com sorvete depois",
      "um encontro casual em uma cafeteria que virou algo especial",
      "uma festa com amigos em comum onde acabaram conversando a noite",
      "um almoço despretensioso que se transformou em um dia inteiro juntos",
    ],
  },
  {
    id: "question_6",
    text: "Onde aconteceu o primeiro beijo?",
    wrongOptions: [
      "na praia",
      "no carro",
      "no elevador",
      "no jardim",
      "no parque",
      "na casa dela",
      "na casa dele",
      "no cinema",
      "no shopping",
      "na faculdade",
      "no trabalho",
      "na festa de um amigo",
      "no restaurante",
      "na balada",
      "no ponto de ônibus",
      "na praça",
      "na escada do prédio",
      "no metrô",
      "na lanchonete",
      "na academia",
    ],
  },
  {
    id: "question_7",
    text: "Qual a comida favorita da noiva?",
    wrongOptions: [
      "sushi",
      "pizza",
      "lasanha",
      "churrasco",
      "feijoada",
      "macarrão",
      "estrogonofe",
      "peixe grelhado",
      "yakisoba",
      "cozido",
      "frango assado",
      "risoto",
      "nhoque",
      "moqueca",
      "acarajé",
      "bobó de camarão",
      "parmegiana",
      "carne de panela",
      "arroz de carreteiro",
      "vatapá",
    ],
  },
  {
    id: "question_8",
    text: "Qual a comida favorita do noivo?",
    wrongOptions: [
      "hambúrguer",
      "tacos",
      "sopa",
      "salada",
      "pizza",
      "churrasco",
      "feijoada",
      "macarrão",
      "estrogonofe",
      "peixe grelhado",
      "yakisoba",
      "cozido",
      "frango assado",
      "risoto",
      "nhoque",
      "moqueca",
      "acarajé",
      "bobó de camarão",
      "parmegiana",
      "carne de panela",
    ],
  },
  {
    id: "question_9",
    text: "Qual o primeiro filme que o casal assistiu juntos?",
    wrongOptions: [
      "Titanic",
      "Avatar",
      "Inception",
      "The Notebook",
      "Star Wars",
      "O Senhor dos Anéis",
      "Harry Potter",
      "Vingadores",
      "Matrix",
      "Jurassic Park",
      "Frozen",
      "Toy Story",
      "Homem-Aranha",
      "Batman",
      "Velozes e Furiosos",
      "Piratas do Caribe",
      "O Rei Leão",
      "Shrek",
      "Os Incríveis",
      "Procurando Nemo",
    ],
  },
  {
    id: "question_10",
    text: "Pra que time o noivo torce?",
    wrongOptions: [
      "Flamengo",
      "Palmeiras",
      "Grêmio",
      "Santos",
      "São Paulo",
      "Corinthians",
      "Internacional",
      "Fluminense",
      "Vasco",
      "Botafogo",
      "Cruzeiro",
      "Atlético-MG",
      "Athletico-PR",
      "Sport",
      "Bahia",
      "Fortaleza",
      "Ceará",
      "Goiás",
      "América-MG",
      "Coritiba",
    ],
  },
  {
    id: "question_11",
    text: "Qual o livro favorito da noiva?",
    wrongOptions: [
      "O Pequeno Príncipe",
      "Dom Quixote",
      "Guerra e Paz",
      "A Divina Comédia",
      "1984",
      "Orgulho e Preconceito",
      "O Senhor dos Anéis",
      "Cem Anos de Solidão",
      "O Alquimista",
      "Harry Potter e a Pedra Filosofal",
      "A Culpa é das Estrelas",
      "O Hobbit",
      "Crime e Castigo",
      "Anna Karenina",
      "Os Miseráveis",
      "Jane Eyre",
      "O Morro dos Ventos Uivantes",
      "A Menina que Roubava Livros",
      "O Código Da Vinci",
      "Memórias Póstumas de Brás Cubas",
    ],
  },
  {
    id: "question_12",
    text: "Onde será a lua de mel?",
    wrongOptions: [
      "Paris",
      "Tóquio",
      "Roma",
      "Barcelona",
      "São Paulo",
      "Nova York",
      "Londres",
      "Dubai",
      "Amsterdã",
      "Veneza",
      "Santorini",
      "Maldivas",
      "Cancún",
      "Bali",
      "Machu Picchu",
      "Sydney",
      "Las Vegas",
      "Miami",
      "Orlando",
      "Cidade do Cabo",
    ],
  },
  {
    id: "question_13",
    text: "Qual o hobby favorito da noiva?",
    wrongOptions: [
      "Viajar",
      "God of War",
      "Ler livros",
      "Cozinhar",
      "Fazer yoga",
      "Jardinagem",
      "Pintura",
      "Fotografia",
      "Dançar",
      "Tocar violão",
      "Assistir séries",
      "Fazer artesanato",
      "Praticar corrida",
      "Colecionar suculentas",
      "Fazer tricô",
      "Cantar karaokê",
      "Jogar videogame",
      "Fazer caminhadas",
      "Meditar",
      "Escrever poesia",
    ],
  },
  {
    id: "question_14",
    text: "Qual o hobby favorito do noivo?",
    wrongOptions: [
      "Viajar",
      "Jogar futebol",
      "Assistir esportes",
      "Jogar videogame",
      "Cozinhar churrasco",
      "Tocar guitarra",
      "Colecionar carros",
      "Praticar musculação",
      "Andar de moto",
      "Pescar",
      "Jogar poker",
      "Assistir filmes de ação",
      "Fazer trilhas",
      "Jogar basquete",
      "Praticar artes marciais",
      "Fazer cerveja artesanal",
      "Montar quebra-cabeças",
      "Jogar tênis",
      "Fazer camping",
      "Fotografar paisagens",
    ],
  },
  {
    id: "question_15",
    text: "Qual comida a noiva não gosta?",
    wrongOptions: [
      "jiló",
      "berinjela",
      "quiabo",
      "fígado",
      "cebola",
      "pimentão",
      "couve-flor",
      "beterraba",
      "repolho",
      "brócolis",
      "pepino",
      "abobrinha",
      "ervilha",
      "cogumelos",
      "sardinha",
      "dobradinha",
      "rabada",
      "língua",
      "bucho",
      "mocotó",
    ],
  },
  {
    id: "question_16",
    text: "Qual comida o noivo não gosta?",
    wrongOptions: [
      "jiló",
      "berinjela",
      "quiabo",
      "fígado",
      "cebola",
      "pimentão",
      "couve-flor",
      "beterraba",
      "repolho",
      "brócolis",
      "pepino",
      "abobrinha",
      "ervilha",
      "cogumelos",
      "sardinha",
      "dobradinha",
      "rabada",
      "língua",
      "bucho",
      "mocotó",
    ],
  },
  {
    id: "question_17",
    text: "Qual o destino dos sonhos da noiva?",
    wrongOptions: [
      "Paris",
      "Maldivas",
      "Grécia",
      "Dubai",
      "Japão",
      "Nova York",
      "Londres",
      "Veneza",
      "Bali",
      "Tailândia",
      "Austrália",
      "Egito",
      "Canadá",
      "Suíça",
      "Irlanda",
      "Islândia",
      "Noruega",
      "Espanha",
      "Portugal",
      "Holanda",
    ],
  },
  {
    id: "question_18",
    text: "Qual o destino dos sonhos do noivo?",
    wrongOptions: [
      "Paris",
      "Maldivas",
      "Grécia",
      "Dubai",
      "Japão",
      "Nova York",
      "Londres",
      "Veneza",
      "Bali",
      "Tailândia",
      "Austrália",
      "Egito",
      "Canadá",
      "Suíça",
      "Irlanda",
      "Islândia",
      "Noruega",
      "Espanha",
      "Portugal",
      "Holanda",
    ],
  },
];
