import Button from "../../../components/ui/Button";
import EventForm, {
  TAdminEventForm,
} from "../../../components/events/EventForm";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useNavigation, useOutletContext } from "react-router-dom";
import { TEvent } from "@services/types";
import { updateEventForAdmin } from "../../../services/events";

export default function ShowEvent() {
  const { event } = useOutletContext() as { event: TEvent };
  const formRef = React.useRef<HTMLFormElement>(null);
  const navigation = useNavigation();
  const scrollDivRef = useRef<HTMLDivElement>(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isEditable, setIsEditable] = React.useState(false);
  const navigate = useNavigate();

  const toggleEditable = () => {
    setIsEditable((prev) => !prev);
  };

  const saveEvent = () => {
    if (scrollDivRef.current) {
      setScrollPosition(scrollDivRef.current.scrollTop);
    }
    formRef.current?.dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );
  };

  const handleUpdate = async (data: TAdminEventForm) => {
    if (isEditable) {
      await updateEventForAdmin(event?.id as string, data);

      setIsEditable(false);

      navigate(`/admin/events/${event?.id}`, { replace: true });
    }
  };

  useEffect(() => {
    if (navigation.state !== "loading") {
      // Restore scroll position after loading
      if (scrollDivRef.current) {
        scrollDivRef.current.scrollTop = scrollPosition;
      }
    }
  }, [navigation.state, scrollPosition]);

  if (navigation.state === "loading") {
    return <div>Loading...</div>;
  }

  return (
    <>
      {event && (
        <div className="h-full w-full flex flex-col justify-start bg-white">
          <div className="w-full flex items-center justify-end gap-5 pt-5 px-5 pb-2">
            {isEditable && (
              <Button
                onClick={saveEvent}
                className="max-w-[150px] bg-green-200"
              >
                Salvar
              </Button>
            )}
            <Button
              onClick={toggleEditable}
              className={`max-w-[150px] ${
                isEditable ? "bg-rose-200" : "bg-red-400 text-white"
              }`}
            >
              {isEditable ? "Cancelar" : "Editar"}
            </Button>
          </div>
          <div className="w-full overflow-y-auto" ref={scrollDivRef}>
            <div className="w-full flex px-10">
              <EventForm
                ref={formRef}
                event={event}
                isEditable={isEditable}
                onSubmit={handleUpdate}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
