import { TEventWithUploads } from "@services/types";
import { useLoaderData } from "react-router-dom";
import { eventTitleFormatter } from "utils/event";
import { GalleryWithSlideshow } from "components/gallery";

export default function PublicGallery() {
  const event = useLoaderData() as TEventWithUploads;
  const eventTitle = eventTitleFormatter(event);

  return (
    <div className="w-full flex flex-col items-center mt-10 mb-16">
      <h1 className="text-3xl text-center font-playfair-display font-normal">
        {eventTitle}
      </h1>

      <div className="w-full mt-10">
        <GalleryWithSlideshow event={event} />
      </div>
    </div>
  );
}
