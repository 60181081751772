const getFileUrl = async (key: string) => {
  const response = await fetch(
    `https://api-n7nfynwxga-uc.a.run.app/v1/storage/file-url?key=${key}&expiration=3600`
  );
  const data = await response.json();
  return data.url as string;
};

const getPresignedUrl = async (filename: string) => {
  const encodedFilename = encodeURIComponent(filename);
  const response = await fetch(
    `https://api-n7nfynwxga-uc.a.run.app/v1/storage/presigned-url/${encodedFilename}`
  );
  const data = await response.json();
  return data.url;
};

const uploadFile = async (
  file: File,
  path: string,
  progressCallback?: (progress: number) => void
) => {
  return new Promise(async (resolve, reject) => {
    let presignedUrl = "";

    try {
      presignedUrl = await getPresignedUrl(path);
    } catch (error) {
      reject("Error getting presigned url");
    }

    const xhr = new XMLHttpRequest();
    xhr.open("PUT", presignedUrl, true);
    xhr.setRequestHeader("Content-Type", file.type);
    let lastEventLoaded = 0;

    xhr.upload.onprogress = (event) => {
      if (
        event.lengthComputable &&
        progressCallback &&
        event.loaded > lastEventLoaded
      ) {
        const nextProgress = (event.loaded / event.total) * 100;

        progressCallback(nextProgress);

        lastEventLoaded = event.loaded;
      }
    };

    xhr.onload = () => {
      if (xhr.status === 200) {
        if (!xhr.responseURL) {
          reject("file url not found in response");
        }

        const fileUrl = new URL(xhr.responseURL);
        fileUrl.search = "";

        resolve(fileUrl.toString());
      } else {
        reject(`${xhr.status} - ${xhr.responseText}`);
      }
    };

    xhr.onerror = () => {
      reject("An error occurred while uploading the file");
    };

    xhr.send(file);
  });
};

export { getFileUrl, getPresignedUrl, uploadFile };
