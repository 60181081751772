type FeatureToggleProps = {
  checked: boolean;
  onChange: (enabled: boolean) => void;
};

const FeatureToggle = (props: FeatureToggleProps) => {
  const { checked, onChange } = props;

  return (
    <div className="flex items-center gap-2">
      <label className="relative inline-flex items-center cursor-pointer">
        <input
          type="checkbox"
          className="sr-only peer"
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
        />
        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-red-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-red-400"></div>
      </label>

      <p className="text-xs text-gray-500">
        {checked ? "ativado" : "clique para ativar"}
      </p>
    </div>
  );
};

export default FeatureToggle;
