export const photoChallengesSchema = [
  {
    id: "challenge_1",
    text: "Tire uma selfie com outros convidados ao fundo dançando",
  },
  {
    id: "challenge_2",
    text: "Encontre alguém que não conhecia antes do casamento e tire uma selfie juntos",
  },
  {
    id: "challenge_3",
    text: "Faça uma pose engraçada com os padrinhos",
  },
  {
    id: "challenge_4",
    text: "Tire uma selfie com alguém que está usando a mesma cor que você",
  },
  {
    id: "challenge_5",
    text: "Tire uma foto com alguém que está sorrindo ou rindo",
  },
  {
    id: "challenge_6",
    text: "Tire uma selfie com a pessoa mais alta da festa",
  },
  {
    id: "challenge_7",
    text: "Encontre três gerações de uma família e tire uma selfie juntos",
  },
  {
    id: "challenge_8",
    text: "Tire uma selfie com o seu prato favorito do buffet",
  },
  {
    id: "challenge_9",
    text: "Faça uma pose criativa com outros convidados formando um coração",
  },
  {
    id: "challenge_10",
    text: "Encontre alguém que veio de outra cidade/estado e tire uma selfie juntos",
  },
  {
    id: "challenge_11",
    text: "Tire uma selfie com a sua bebida favorita da festa",
  },
  {
    id: "challenge_12",
    text: "Tire uma selfie com o pai ou a mãe do noivo",
  },
  {
    id: "challenge_13",
    text: "Tire uma selfie com o pai ou a mãe da noiva",
  },
  {
    id: "challenge_14",
    text: "Encontre outros convidados e forme a palavra AMOR com os corpos",
  },
  {
    id: "challenge_15",
    text: "Tire uma selfie com a decoração que você mais gostou na festa",
  },
  {
    id: "challenge_16",
    text: "Tire uma selfie com alguém que está usando óculos",
  },
  {
    id: "challenge_17",
    text: "Tire uma selfie com alguém que está usando o vestido mais bonito da festa (sem contar a noiva)",
  },
  {
    id: "challenge_18",
    text: "Tire uma selfie com alguém que está usando gravata",
  },
  {
    id: "challenge_19",
    text: "Tire uma selfie com alguém que está usando sapatos brilhantes. O sapato deve aparecer na foto.",
  },
  {
    id: "challenge_20",
    text: "Tire uma selfie com alguém que está usando um relógio",
  },
  {
    id: "challenge_21",
    text: "Tire uma selfie com alguém que está usando uma pulseira",
  },
  {
    id: "challenge_22",
    text: "Tire uma selfie com alguém que está usando um colar",
  },
  {
    id: "challenge_23",
    text: "Tire uma selfie com alguém que está usando um cinto",
  },
  {
    id: "challenge_24",
    text: "Tire uma selfie com alguém que está usando um broche",
  },
  {
    id: "challenge_25",
    text: "Tire uma selfie com alguém que está usando um anel",
  },
  {
    id: "challenge_26",
    text: "Tire uma selfie com a pessoa mais estilosa da festa",
  },
  {
    id: "challenge_27",
    text: "Tire uma selfie com o noivo",
  },
  {
    id: "challenge_28",
    text: "Tire uma selfie com a noiva",
  },
  {
    id: "challenge_29",
    text: "Tire uma selfie a pessoa mais engraçada da festa",
  },
  {
    id: "challenge_30",
    text: "Tire uma selfie com a pessoa mais bonita da festa",
  },
];
