type InputProps = {
  label: string;
  register: any;
  error?: any;
  field: string;
  type?: string;
  hint?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

function InputToggle(props: InputProps) {
  const {
    label,
    register,
    error,
    field,
    type,
    value,
    defaultChecked,
    hint,
    ...rest
  } = props;

  return (
    <div className="flex items-center gap-2">
      <label
        key={label}
        htmlFor={field}
        className="relative inline-flex items-center cursor-pointer"
      >
        <input
          type="checkbox"
          {...register}
          name={field}
          id={field}
          value={value}
          className="sr-only peer"
          {...rest}
        />

        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-red-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-red-400"></div>
      </label>
      <p className="text-xs text-gray-500">{label}</p>
      {hint && <span className="text-xs text-gray-500">{hint}</span>}
    </div>
  );
}

export default InputToggle;
