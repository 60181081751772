export const teamMatchSchema = [
  {
    id: "question_1",
    text: "O que você prefere?",
  },
  {
    id: "question_2",
    text: "Qual é seu estilo de festa ideal?",
  },
  {
    id: "question_3",
    text: "Qual bebida você escolheria?",
  },
  {
    id: "question_4",
    text: "Como seria seu fim de semana perfeito?",
  },
  {
    id: "question_5",
    text: "Qual presente você prefere ganhar?",
  },
  {
    id: "question_6",
    text: "Qual seu tipo de filme favorito?",
  },
  {
    id: "question_7",
    text: "Como você prefere se exercitar?",
  },
  {
    id: "question_8",
    text: "Qual sobremesa você escolheria?",
  },
  {
    id: "question_9",
    text: "Como você organiza suas coisas?",
  },
  {
    id: "question_10",
    text: "Qual animal você prefere?",
  },
  {
    id: "question_11",
    text: "Como você gosta de viajar?",
  },
  {
    id: "question_12",
    text: "Qual seu estilo de decoração preferido?",
  },
  {
    id: "question_13",
    text: "Como você prefere comemorar seu aniversário?",
  },
  {
    id: "question_14",
    text: "Qual seu tipo de música favorito?",
  },
  {
    id: "question_15",
    text: "Como você gosta de passar um feriado?",
  },
];
