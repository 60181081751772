import FirebaseFirestoreService from "../libs/firebase/FirebaseFirestoreService";
import { TEvent } from "./types";

// Normalize contacts whatsapp phones to the international format +55xxxxxxxxxx
const migrate = async () => {
  console.log("Starting data migration");

  const query = {
    collection: "events",
    queries: [],
  };
  const eventsResponse = await FirebaseFirestoreService.readDocuments(query);

  const events = eventsResponse.docs.map((doc) => {
    return {
      id: doc.id,
      ...doc.data(),
    } as TEvent;
  });

  console.log(`Found ${events.length} events`);

  events.forEach(async (event) => {
    console.log(`Updating event ${event.id}`);

    let hasChanges = false;

    const contactsUpdated = await event.contacts?.map((contact) => {
      if (contact.whatsapp) {
        const whatsappOnlyNumbers = contact.whatsapp.replace(/[^0-9]/g, "");
        let newWhatsappValue;

        // If the number is in the national format
        if (
          whatsappOnlyNumbers.length <= 11 &&
          whatsappOnlyNumbers.length >= 8 &&
          !whatsappOnlyNumbers.startsWith("55")
        ) {
          newWhatsappValue = `+55${whatsappOnlyNumbers}`;
        }

        // If the number is already in the international format
        if (whatsappOnlyNumbers.startsWith("55")) {
          newWhatsappValue = `+${whatsappOnlyNumbers}`;
        }

        if (newWhatsappValue) {
          console.log("Contact whatsapp updated", {
            eventId: event.id,
            contact: contact.name,
            curentValue: contact.whatsapp,
            newValue: newWhatsappValue,
          });

          contact.whatsapp = newWhatsappValue;

          hasChanges = true;
        }
      }

      return contact;
    });

    if (hasChanges) {
      event.contacts = contactsUpdated;

      await FirebaseFirestoreService.updateDocument(
        "events",
        event.id as string,
        event
      );
    }
  });

  console.log("Data migration finished");
};

const dataMigrationService = { migrate };

export default dataMigrationService;
